import Link from "next/link"
import Image from "next/image"
import { useState } from "react"
import { trackProductClick } from "@/lib/helpers/tagManager"
import Blocks from "@/components/Blocks"
import styles from "./blurb.module.css"
import classNames from "classnames"

export default function blurb({ block }) {
  const { innerBlocks, attrs } = block
  const settings = {
    "core/heading": { width: "bleed" },
    "core/paragraph": { width: "bleed" }
  }

  const Wrapper = ({ children }) => {
    const trackProduct = () => trackProductClick(attrs?.tracking, "gift")
    const classes = [
      styles.blurb,
      "blurb",
      {
        "hide-button": attrs.hideButton
      }
    ]

    if (attrs.url.length === 0 || !attrs.hideButton) {
      return <div className={classNames(classes)}>{children}</div>
    }

    if (attrs.targetBlank) {
      return (
        <div className={classNames(classes)}>
          <a
            href={attrs.url}
            target="_black"
            rel="noreferrer"
            onClick={trackProduct}
            className="cover"></a>
          {children}
        </div>
      )
    }
    return (
      <div className={classNames(classes)}>
        <Link href={attrs.url} prefetch={false}>
          <a onClick={trackProduct} className="cover"></a>
        </Link>
        {children}
      </div>
    )
  }

  if (attrs?.isEmpty) {
    return null
  }

  return (
    <Wrapper>
      <Media
        media={attrs.media}
        layout={attrs.layout}
        image={attrs.image}
        address={attrs.address}
        zoom={attrs.zoom}
      />
      <Blocks
        tracking={attrs.tracking}
        blocks={innerBlocks}
        settings={settings}
      />
    </Wrapper>
  )
}

export function Media({ media, layout, image, address, zoom }) {
  const mapKey = process.env.NEXT_PUBLIC_GOOGLE_MAP_KEY
  const size = layout === "fourth" ? "720x720" : "720x540"
  const [state, setState] = useState("loading")

  const query =
    new URLSearchParams({
      key: mapKey,
      zoom: zoom,
      scale: 2,
      size: size,
      markers: address
    }).toString() + "&style=feature:water|geometry.fill|color:0xa0b5d5"

  const mapSrc =
    media === "map" && address.length > 0
      ? "https://maps.googleapis.com/maps/api/staticmap?" + query
      : null

  if (media === "image" && !image) {
    return null
  }

  if (media === "map" && !mapSrc) {
    return null
  }

  return (
    <div
      className={classNames([
        "media-wrapper",
        state,
        { fourth: layout === "fourth" }
      ])}>
      <figure>
        {media === "image" && (
          <Image
            className={styles.image}
            onLoadingComplete={() => setState(false)}
            alt={image.alt}
            src={image.url}
            sizes={image.sizes}
            layout="fill"
          />
        )}
        {media === "map" && (
          <img
            className={styles.map}
            alt={"Karta till " + address}
            src={mapSrc}
          />
        )}
      </figure>
    </div>
  )
}
